<template>
  <div
    class="ma-0 mx-auto"
    style="min-height: 100%; max-width: 700px;"
  >
    <v-card
      style="max-width: 700px;"
      class="mx-auto py-2"
    >
      <h2
        class="mt-0 mb-5"
      >My Properties</h2>

      <v-expansion-panels
        v-if="properties && properties.length > 0"
        :key="properties.length"
        class="mx-auto px-0"
        popout
        flat
        tile
        hover
      >
        <v-expansion-panel
          v-for="(p, i) in properties"
          :key="'property' + i"
          class="mb-1"
        >
          <v-expansion-panel-header
            color="#E6E6E6"
          >
          <span><b>Lot #{{ p.lot }}</b>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;{{ p.member_of_record }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="px-6 pt-3"
            color="white"
          >
            <v-card
              class="pa-3"
              flat
            >
              <v-card-title>
                <h3
                  class="mb-2"
                >Lot #{{ p.lot }}</h3>
              </v-card-title>

              <v-card-subtitle>
                <h4
                  class="text-left"
                  style="color: #444;"
                >{{ p.member_of_record }}</h4>
              </v-card-subtitle>

              <v-card-text
                class="d-flex"
              >
                <h5
                  class="text-left"
                >
                  {{ p.line1 }}<br>
                  {{ p.line2 }}<br>
                  {{ p.email }}<br>
                  {{ toTel(p.phone) }}<br>
                  {{ p.license_plates }}
                </h5>

                <v-btn
                  fab
                  color="primary"
                  class="ml-auto mt-auto mb-0"
                  :to="'/dashboard/' + $route.path.match(/member|manager/i)[0] + '/property/' + p.id"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
/* IMPORTS */

export default {
  name: 'MyProperties',

  components: {

  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },

    properties: {
      type: Array,
      required: true,
    }
  },

  data: () => ({

  }),

  watch: {

  },

  methods: {
    toTel: (s) => {
      if (!s) return '';
      let f = s.replace(/[\x2b\x28\x29\x2d\x20]/g, '');
      let t = '';
      for (let i = 0; i < f.length; i++) {
        if (i === 1) t += ' ('; if (i === 4) t += ') '; if (i === 7) t += '-';
        t += f[i];
      }
      return t;
    }
  },

  computed: {

  },

  created () {

  },

  mounted () {

  },
};
</script>
