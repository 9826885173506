<template>
  <div
    :style="'position: relative; min-height: 100%; background: var(' + background + ');'"
  >
    <div
      v-if="(permissions === 2 || permissions === 4) && $route.path.match(/member|manager/i)"
      style="width: 100%; background: white;"
    >
      <v-tabs
        v-model="tab"
        class="d-none d-md-flex align-center justify-center flex-wrap"
        style="width: 100%;"
        slider-color="accent"
        color="secondary"
      >
        <v-tab
          outlined
          :to="'/dashboard/' + $route.path.match(/member|manager/i)[0] + '/my-account'"
        >My Account</v-tab>

        <v-tab
          v-if="properties.length > 0"
          :to="'/dashboard/' + $route.path.match(/member|manager/i)[0] + '/properties'"
        >Properties</v-tab>

        <v-tab
          :to="'/dashboard/' + $route.path.match(/member|manager/i)[0] + '/payments'"
        >Payments</v-tab>

        <v-tab
          v-if="properties.length > 0"
          :to="'/dashboard/' + $route.path.match(/member|manager/i)[0] + '/rentals'"
        >Rentals</v-tab>

        <v-tab
          v-if="properties.length > 0"
          :to="'/dashboard/' + $route.path.match(/member|manager/i)[0] + '/rental-form'"
        >Create Rental</v-tab>
      </v-tabs>
    </div>

    <router-view
      class="py-5"

      :env="env"
      :permissions="permissions"
      :lastRefresh="lastRefresh"
      :userInfo="userInfo"
      :properties="properties"
      :alerts="alerts"
      :rentals="rentals"
      :propList="propList"
      :payments="payments"
      :loading="loading"

      @refresh-alerts="$emit('refresh-alerts')"
      @refresh-rentals="$emit('refresh-rentals')"
      @refresh-prop-list="$emit('refresh-prop-list')"
      @refresh-payments="$emit('refresh-payments')"
      @refresh-userinfo="$emit('refresh-userinfo')"
      @refresh-properties="$emit('refresh-properties')"
      @success="$emit('success', $event)"
      @error="$emit('error', $event)"
      @info="$emit('info', $event)"
      @loading="$emit('loading', $event)"
    />
  </div>
</template>

<script>
import { setPerms } from '@/assets/js/constants.js';

export default {
  name: 'Dashboard',

  props: {
    env: {
      type: Object,
      required: true,
    },

    permissions: {
      type: Number,
      required: true,
    },

    lastRefresh: {
      type: Number,
      required: true,
    },

    userInfo: {
      type: Object,
      required: false,
      default: {},
    },

    properties: {
      type: Array,
      required: false,
      default: [],
    },

    alerts: {
      type: Object,
      required: false,
      default: [],
    },

    rentals: {
      type: Array,
      required: false,
      default: [],
    },

    propList: {
      type: Array,
      required: true,
      default: [],
    },

    payments: {
      type: Array,
      required: false,
      default: [],
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    background: 'white',
    tab: 0,
    uiKey: 0,
  }),

  computed: {

  },

  methods: {

  },

  /**
   * Redirects user to correct landing page after logging in based on their permissions.
   * This will also block logged out users from accessing the dashboard view.
   */
  created () {
    // Triggers to show header if a user is logged in
    this.$emit('loggedIn', true);

    // ADMIN Permissions
    if ((this.permissions & setPerms.ADMIN) === setPerms.ADMIN) {
      if (this.$route.path.indexOf('/dashboard/admin') !== 0 && this.$route.path.indexOf('/dashboard/change-password') !== 0)
        this.$router.replace('/dashboard/admin/settings');
    }
    // MEMBER Permissions
    else if ((this.permissions & setPerms.MEMBER) === setPerms.MEMBER) {
      this.background = '--secondary';
      if (this.$route.path.indexOf('/dashboard/member') !== 0 && this.$route.path.indexOf('/dashboard/change-password') !== 0)
        this.$router.replace('/dashboard/member/my-account');
    }
    // MANAGER Permissions
    else if ((this.permissions & setPerms.MANAGER) === setPerms.MANAGER) {
      this.background = '--secondary';
      if (this.$route.path.indexOf('/dashboard/manager') !== 0 && this.$route.path.indexOf('/dashboard/change-password') !== 0)
        this.$router.replace('/dashboard/manager/my-account');
    }
    // GUEST Permissions
    else if ((this.permissions & setPerms.GUEST) === setPerms.GUEST) {
      this.background = '--secondary';
      if (this.$route.path.indexOf('/dashboard/guest') !== 0 && this.$route.path.indexOf('/dashboard/change-password') !== 0)
        this.$router.replace('/dashboard/guest');
    }
    else if (this.permissions === 0) {
      this.$router.push('/')
    }

  },
};
</script>
