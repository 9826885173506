import { render, staticRenderFns } from "./Payment.vue?vue&type=template&id=4265138a&scoped=true&"
import script from "./Payment.vue?vue&type=script&lang=js&"
export * from "./Payment.vue?vue&type=script&lang=js&"
import style0 from "./Payment.vue?vue&type=style&index=0&id=4265138a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4265138a",
  null
  
)

export default component.exports