<template>
  <div
    class="d-flex flex-column align-center"
  >
    <h2
      style="font-size: 24px; margin-bottom: 15px;"
    >{{ title }}</h2>

    <div
      class="d-flex align-center"
    >
      <v-btn
        v-if="!noAdd"
        color="primary"
        dark
        @click="addItem"
      >Add Entry</v-btn>

      <v-divider
        v-if="!noAdd && download"
        class="mx-4"
        inset
        vertical
      ></v-divider>

      <v-btn
        v-if="download"
        color="accent"
        dark
        @click="downloadTable"
      >Download</v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="10"
      :search="search"
      :custom-filter="filter"
      :loading="loading"
      multi-sort
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>

      <template #item.amount="{ value }">
        ${{ value }}
      </template>

      <template #item.property_url="{ value }">
        <a :href="getPropertyURL(value)" target="_blank">
          {{ value }}
        </a>
      </template>

      <template #item.payment_date="{ value }">
        <div
          v-if="value && value.length > 0"
        >
          {{ convertDateTime(value) }}
        </div>

        <div
          v-else
        ></div>
      </template>

      <template #item.date_of="{ value }">
        <div
          v-if="value && value.length > 0"
        >
          {{ convertDate(value) }}
        </div>

        <div
          v-else
        ></div>
      </template>

      <template #item.start_date="{ value }">
        <div
          v-if="value && value.length > 0"
        >
          {{ convertDate(value) }}
        </div>

        <div
          v-else
        ></div>
      </template>

      <template #item.end_date="{ value }">
        <div
          v-if="value && value.length > 0"
        >
          {{ convertDate(value) }}
        </div>

        <div
          v-else
        ></div>
      </template>

      <template #item.check_in="{ value }">
        <div
          v-if="value && value.length > 0"
        >
          {{ convertDate(value) }}
        </div>

        <div
          v-else
        ></div>
      </template>

      <template #item.check_out="{ value }">
        <div
          v-if="value && value.length > 0"
        >
          {{ convertDate(value) }}
        </div>

        <div
          v-else
        ></div>
      </template>

      <template #item.due_date="{ value }">
        <div
          v-if="value && value.length > 0"
        >
          {{ convertDate(value) }}
        </div>

        <div
          v-else
        ></div>
      </template>

      <template #item.actions="{ item }">
        <div
          class="d-flex align-center justify-center"
        >
          <v-icon
            v-if="!noEdit"
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>

          <v-icon
            v-if="!noDelete"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "DashboardTable",

  props: {
    title: {
      type: String,
      required: true,
    },

    headers: {
      type: Array,
      required: true,
    },

    formInputs: {
      type: Object,
      required: true,
    },

    data: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    noEdit: {
      type: Boolean,
      required: false,
      default: false,
    },

    noAdd: {
      type: Boolean,
      required: false,
      default: false,
    },

    noDelete: {
      type: Boolean,
      required: false,
      default: false,
    },

    download: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    search: null,
    item: {},
  }),

  methods: {
    convertDate(d) {
      const d2 = new Date();
      d2.setFullYear(parseInt(d.slice(0, 4)), parseInt(d.slice(5, 7)) - 1, parseInt(d.slice(8, 10)));
      d2.setHours(0, 0, 0);
      return d2.toDateString().slice(4);
    },

    convertDateTime(d) {
      const d2 = new Date(d);
      return d2.toString().slice(4, 24)
    },

    filter (value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1
      );
    },

    downloadTable() {
      let csv = '\uFEFF'; // Universal Byte Order Mark (BOM)

      // Populate headers
      const headers = [];
      for (const header of this.headers) {
        if (header.text !== 'Actions') {
          for (const key of Object.keys(this.formInputs)) {
            if (this.formInputs[key].label === header.text) {
              headers.push(key);
              break;
            }
          }
          csv += header.text + ','
        }
      }
      csv = csv.slice(0, -1) + '\r\n';

      // Populate data
      for (const d of this.data) {
        for (const header of headers) {
          const contents = unescape(d[header]);
          if (contents === null || contents === 'null') {
            csv += ',';
          }
          else if (typeof contents === 'string') {
            if (contents.charAt(0) === '"' && contents.charAt(contents.length - 1) === '"') {
              console.log(contents)
              csv += '""' + contents.replace(/"/g, '\"') + '"",'
            }
            else if (/[,;\r\n]/.test(contents)) {
              csv += '"' + contents.replace(/"/g, '""') + '",'
            }
            else {
              csv += contents.replace(/"/g, '""') + ','
            }
          }
          else {
            csv += contents + ',';
          }
        }
        csv = csv.slice(0, -1) + '\n';
      }

      // Create file and trigger download
      let e = document.createElement('a');
      e.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      e.target = '_blank';
      e.download = this.title + '-' + (new Date()).toISOString().slice(0, 19) + '.csv';
      e.click();
    },

    addItem () {
      this.$emit("add");
    },

    editItem (item) {
      this.$emit("edit", item);
    },

    deleteItem (item) {
      this.$emit("delete", item);
    },

    /**
   * @summary Unescapes Strings and is recursively called on Arrays and Objects.
   * @param {String|Array|Object} body - Item to unescape.
   * @returns {String|Array|Object} Unescaped version of what was put in.
   */
    unescapeHelper: (body) => {
      // Base case if string
      if (typeof body === 'string')
        return unescape(body);

      // Iterate over Array
      else if (Array.isArray(body)) {
        body.forEach((item, i) => {
          body[i] = module.exports.unescapeHelper(body[i]);
        })
      }

      // Iterate over Object keys
      else if (body instanceof Object) {
        Object.keys(body).forEach(key => {
          body[key] = module.exports.unescapeHelper(body[key]);
        });
      }

      return body;
    },

    getPropertyURL: (id) => {
      return 'https://property.whatcomcounty.us/propertyaccess/Property.aspx?cid=0&year=' + (new Date().getFullYear()) + '&prop_id=' + id;
    },
  },
};
</script>
