import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login.vue'
import Reset from '@/views/Reset.vue'

import Dashboard from '@/views/Dashboard.vue'
import ChangePassword from '@/views/dashboard/ChangePassword.vue'

import Guest from '@/views/dashboard/Guest.vue'

import Manager from '@/views/dashboard/Manager.vue'

import Member from '@/views/dashboard/Member.vue'
import Properties from '@/views/dashboard/member/Properties.vue'
import Property from '@/views/dashboard/member/Property.vue'
import Account from '@/views/dashboard/member/Account.vue'
import Payments from '@/views/dashboard/member/Payments.vue'
import Payment from '@/views/dashboard/member/Payment.vue'
import Rentals from '@/views/dashboard/member/Rentals.vue'
import RentalForm from '@/views/dashboard/member/RentalForm.vue'

import Admin from '@/views/dashboard/Admin.vue'
import EmailCampaign from '@/views/dashboard/admin/EmailCampaign.vue'
import ResendActivation from '@/views/dashboard/admin/ResendActivation.vue'
import CRUDManagement from '@/components/crud-management/CRUDManagement.vue'
import ResetLoginAttempts from '@/views/dashboard/admin/ResetLoginAttempts.vue'

import * as CRUDFormInputs from '@/assets/structures/CRUDFormInputs.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: `${process.env.VUE_APP_SHORT_TITLE} | Login`
    },
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: {
      title: `${process.env.VUE_APP_SHORT_TITLE} | Reset Password`
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: `${process.env.VUE_APP_SHORT_TITLE} | Dashboard`
    },
    children: [
      {
        path: 'change-password',
        component: ChangePassword,
        meta: {
          title: `${process.env.VUE_APP_SHORT_TITLE} | Change Password`
        },
      },
      {
        path: 'guest',
        component: Guest,
        meta: {
          title: `${process.env.VUE_APP_SHORT_TITLE} | Dashboard`
        },
        children: [

        ],
      },
      {
        path: 'manager',
        component: Manager,
        children: [
          {
            path: 'my-account',
            component: Account,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | My Account`
            },
          },
          {
            path: 'properties',
            component: Properties,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Properties`
            },
          },
          {
            path: 'property/:property_id',
            component: Property,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Property`
            },
          },
          {
            path: 'payments',
            component: Payments,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Payments`
            },
          },
          ,
          {
            path: 'payment',
            component: Payment,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Payment`
            },
          },
          {
            path: 'rentals',
            component: Rentals,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Rentals`
            },
          },
          {
            path: 'rental-form',
            component: RentalForm,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Rental Form`
            },
          },
          {
            path: 'rental-form/:rental_id',
            component: RentalForm,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Rental`
            },
          },
        ]
      },
      {
        path: 'member',
        component: Member,
        children: [
          {
            path: 'properties',
            component: Properties,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Properties`
            },
          },
          {
            path: 'property/:property_id',
            component: Property,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Property`
            },
          },
          {
            path: 'my-account',
            component: Account,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | My Account`
            },
          },
          {
            path: 'rental-form',
            component: RentalForm,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Rental Form`
            },
          },
          {
            path: 'rental-form/:rental_id',
            component: RentalForm,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Rental`
            },
          },
          {
            path: 'payments',
            component: Payments,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Payments`
            },
          },
          ,
          {
            path: 'payment',
            component: Payment,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Payment`
            },
          },
          {
            path: 'rentals',
            component: Rentals,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Rentals`
            },
          },
        ],
      },
      {
        path: 'admin',
        component: Admin,
        meta: {
          title: `${process.env.VUE_APP_SHORT_TITLE} | Admin Dashboard`
        },
        children: [
          {
            path: 'master',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Master`
            },
            props: {
              title: 'Master',
              endpoint: '/master',
              download: true,
              noAdd: true,
              noEdit: true,
              noDelete: true,
              formInputs: CRUDFormInputs.master,
            },
          },
          {
            path: 'settings',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Settings`
            },
            props: {
              title: 'Settings',
              endpoint: '/globals',
              noEdit: false,
              download: false,
              noAdd: true,
              noDelete: true,
              formInputs: CRUDFormInputs.globals,
            },
          },
          {
            path: 'manage-users',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Users`
            },
            props: {
              title: 'Users',
              endpoint: '/users',
              noEdit: false,
              download: true,
              formInputs: CRUDFormInputs.users,
            },
          },
          {
            path: 'manage-properties',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Properties`
            },
            props: {
              title: 'Properties',
              endpoint: '/properties',
              noEdit: false,
              download: true,
              formInputs: CRUDFormInputs.properties,
            },
          },
          {
            path: 'manage-violations',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Violations`
            },
            props: {
              title: 'Violations',
              endpoint: '/violations',
              noEdit: false,
              download: true,
              formInputs: CRUDFormInputs.violations,
            },
          },
          {
            path: 'manage-dues',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Dues`
            },
            props: {
              title: 'Dues',
              endpoint: '/dues',
              noEdit: true,
              download: true,
              formInputs: CRUDFormInputs.dues,
            },
          },
          {
            path: 'manage-alerts',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Alerts`
            },
            props: {
              title: 'Alerts',
              endpoint: '/alerts',
              noEdit: false,
              download: true,
              formInputs: CRUDFormInputs.alerts,
            },
          },
          {
            path: 'manage-rentals',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Rentals`
            },
            props: {
              title: 'Rentals',
              endpoint: '/rentals',
              noAdd: true,
              noEdit: false,
              download: true,
              formInputs: CRUDFormInputs.rentals,
            },
          },
          {
            path: 'manage-payments',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Payments`
            },
            props: {
              title: 'Payments',
              endpoint: '/payments',
              noEdit: true,
              download: true,
              formInputs: CRUDFormInputs.payments,
            },
          },
          {
            path: 'manage-property-dues',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Property Dues`
            },
            props: {
              title: 'Property Dues',
              endpoint: '/properties_x_dues',
              noEdit: false,
              download: true,
              formInputs: CRUDFormInputs.properties_x_dues,
            },
          },
          {
            path: 'manage-property-managers',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Property Managers`
            },
            props: {
              title: 'Property Managers',
              endpoint: '/users_x_properties',
              noEdit: true,
              noDelete: false,
              download: true,
              formInputs: CRUDFormInputs.users_x_properties,
            },
          },
          {
            path: 'members-owe',
            component: CRUDManagement,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Members Owe`
            },
            props: {
              title: 'Members Owe',
              endpoint: '/users/members_owe',
              noEdit: true,
              noDelete: true,
              noAdd: true,
              download: true,
              formInputs: CRUDFormInputs.members_owe,
            },
          },
          {
            path: 'email',
            component: EmailCampaign,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Email Campaign`
            },
          },
          {
            path: 'resend-activation',
            component: ResendActivation,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Resend Activation`
            },
          },
          {
            path: 'reset-login-attempts',
            component: ResetLoginAttempts,
            meta: {
              title: `${process.env.VUE_APP_SHORT_TITLE} | Reset Login Attempts`
            },
          },
        ],
      },
    ],
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
