<template>
  <div>
    <h2
      style="font-size: 24px; margin-bottom: 15px;"
    >Reset Login Attempts</h2>

    <h4
      v-if="maxLoginAttempts && maxLoginAttempts > 0"
    >Users will not be able to login if they have {{ maxLoginAttempts }} login attempts</h4>

      <v-card
      style="max-width: 600px;"
      class="mx-auto d-flex flex-column align-center"
      flat
    >
      <v-text-field
        v-model="search"
        label="Search"
        style="width: 100%;"
        @change="filter"
      />

      <table
        class="mx-auto px-0 mb-10"
        accordion
        style="width: 100%;"
      >
        <thead
          style="width: 100%;"
        >
          <tr
            class="d-flex align-center justify-space-between"
            style="width: 100%"
          >
            <th
              class="text-left"
              style="width: 100%;"
            >User</th>

            <th
              style="width: 50%;"
            >Login Attempts</th>

            <th
              style="width: 30%;"
            >Action</th>
          </tr>
        </thead>

        <tbody
          style="width: 100%;"
        >
          <tr
            v-for="(u, i) in filteredUsers"
            :key="'user' + i"
            class="d-flex align-center justify-space-between"
            style="width: 100%;"
          >
            <td
              class="text-left"
              style="width: 100%;"
            >
              <div
                style="overflow-wrap: anywhere;"
              >{{ u.fname }} {{ u.lname }}</div>

              <div
                style="overflow-wrap: anywhere;"
              >{{ u.email }}</div>
            </td>

            <td
              class="text-center"
              style="width: 50%;"
            >{{ u.login_attempts }}</td>

            <td
              class="d-flex justify-center"
              style="width: 30%;"
            >
              <v-btn
                @click="reset(u)"
                color="green"
                style="color: white;"
              >Reset</v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>
<script>
import fetchV2 from '../../../assets/js/fetchV2';

export default {
  name: 'ResetLoginAttempts',

  components: {

  },

  props: {

  },

  data: () => ({
    maxLoginAttempts: null,
    search: '',
    users: [],
    filteredUsers: [],
  }),

  watch: {

  },

  computed: {

  },

  methods: {
    filter() {
      const filteredUsers = [];
      for (const user of this.users) {
        if (
          user.fname.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          user.lname.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          user.email.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        ) {
          filteredUsers.push(user);
        }
      }
      this.filteredUsers = filteredUsers;
    },

    getMaxLoginAttempts() {
      fetchV2('GET /globals/max-login-attempts').then(maxLoginAttempts => {
        this.$emit('loading', false);
        this.maxLoginAttempts = maxLoginAttempts;
        this.$forceUpdate();
      }).catch(err => {
        this.$emit('error', 'Unable to get max login attempts.');
      })
    },

    getLockedUsers() {
      this.$emit('loading', 'Getting locked users');
      fetchV2('GET /users/locked').then(users => {
        this.users = users;
        this.$emit('loading', false);
        this.filter();
        this.$forceUpdate();
      }).catch(err => {
        this.$emit('error', 'Unable to get locked users.');
      })
    },

    reset(user) {
      this.$emit('loading', 'Resetting user login attempts');
      fetchV2('POST /users/reset-login-attempts', { id: user.id, email: user.email }).then(() => {
        this.$emit('success', user.fname + ' ' + user.lname + ' <' + user.email + '> login attempts have been reset.');
        this.getLockedUsers();
      }).catch(err => {
        this.$emit('error', 'Unable to reset account.');
      })
    }
  },

  destroyed() {

  },

  created() {
    this.getMaxLoginAttempts();
    this.getLockedUsers();
  },

  mounted() {

  }
}
</script>
<style scoped>
  tr {
    padding: 0 6px;
  }
  tr:nth-child(odd) {
    background: #F0F0F0;
  }
</style>
