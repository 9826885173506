<template>
  <div class="d-flex justify-center align-center" style="min-height: 100%; background: var(--secondary);">
    <v-card
      v-if="!resetView"
      class="d-flex flex-column align-center pa-5"
      style="width: 100%; max-width: 400px;"
    >

      <h1>{{ env.longName }}</h1>

      <div
        class="mt-2 logo"
      ></div>

      <v-form
        class="pa-5 pt-3 d-flex justify-center align-center flex-column"
        style="width: 100%;"
        @submit="login"
      >

        <v-text-field
          v-model="email"
          label="Email"
          oullined
          style="width: 100%;"
          required
        />

        <v-text-field
          v-model="password"
          label="Password"
          oullined
          :type="pwdVisType"
          style="width: 100%;"
          :append-icon="pwdVisIcon"
          @click:append="pwdVis"
          required
        />

        <v-btn
          type="submit"
          color="primary"
          class="mt-3"
          style="min-width: 120px;"
          :loading="loading"
        >
          Login
        </v-btn>

      </v-form>

      <v-btn
        text
        small
        class="my-2"
        @click="resetView=true"
      >
        Forgot your password?
      </v-btn>

    </v-card>

    <v-card
      v-if="resetView"
      class="d-flex flex-column align-center pa-5"
      style="width: 100%; max-width: 400px;"
    >
      <h1>{{ env.longName }}</h1>

      <img
        :src="env.logo"
        class="mt-3"
        height="80px"
        style="height: 80px; border-radius: 100%; overflow: hidden;"
      />

      <form
        class="pa-5 pt-3 d-flex justify-center align-center flex-column"
        style="width: 100%;"
        @submit="reset"
      >

        <v-text-field
          v-model="email"
          label="Email"
          oullined
          style="width: 100%;"
          required
        />

        <v-btn
          type="submit"
          color="primary"
          class="mt-3"
          style="min-width: 120px;"
          :loading="loading"
        >
          Reset
        </v-btn>

      </form>

      <v-btn
        text
        small
        class="my-2"
        @click="resetView=false"
      >
        Back
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import fetchV2 from '../assets/js/fetchV2';

export default {
  name: 'Login',

  props: {
    env: {
      type: Object,
      required: true,
    },

    permissions: {
      type: Number,
      required: true,
    }
  },

  data: () => ({
    alertError: false,
    error: null,
    email: null,
    password: null,
    loading: false,
    resetView: false,
    attempts: 0,
    pwdVisIcon: 'mdi-eye-off-outline',
    pwdVisType: 'password',
  }),

  methods: {
    pwdVis() {
      if (this.pwdVisIcon === 'mdi-eye-off-outline') {
        this.pwdVisIcon = 'mdi-eye-outline';
        this.pwdVisType = 'text';
      }
      else {
        this.pwdVisIcon = 'mdi-eye-off-outline';
        this.pwdVisType = 'password';
      }
    },

    errorLoggingIn(err) {
      this.email = null;
      this.password = null;
      this.loading = false;
      if (err != null && err.msg != null)
        this.$emit('error', err.msg);
      else {
        this.attempts++;
        this.$emit('error', 'Incorrect email or password.');
      }
    },

    login(e) {
      this.loading = true;
      e.preventDefault();
      fetchV2('POST /users/authenticate', {
        email: this.email.trim().toLowerCase(),
        password: this.password
      }).then(data => {
        if (data != null && data.permissions != null) {
          this.$emit('permissions', data.permissions);
        }
        else {
          this.errorLoggingIn({ msg: 'Error logging in.' });
        }
      })
      .catch(err => {
        this.errorLoggingIn()
      })
    },

    getImg(pic) {
      return require('../assets/' + pic)
    },

    reset(e) {
      e.preventDefault();
      fetchV2('POST /users/forgot', {
        email: this.email.trim().toLowerCase()
      }).then(data => {
        this.loading = false;
        this.$emit('success', data.message);
      }).catch((err) => {
        console.error(err);
        this.loading = false;
        this.$emit('error', 'Error resetting password.');
      })
    },

  },

  created () {
    this.$emit('loggedIn', false);
    if (this.attempts == null) {
      this.attempts = 0;
    }
  },
};
</script>

<style scoped>
  .gradient-background {
    background-image: linear-gradient(270deg,  var(--gray) 0%, var(--primary) 100% );
  }
</style>
