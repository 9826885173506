<template>
  <div>
    <router-view
      :env="env"
      :permissions="permissions"
      :personalModel="userInfo.info ? userInfo.info : {}"
      :settings="userInfo.settings ? userInfo.settings : {}"
      :overduePayments="alerts.overduePayments ? alerts.overduePayments : []"
      :upcomingPayments="alerts.upcomingPayments ? alerts.upcomingPayments : []"
      :generalAlerts="alerts.generalAlerts ? alerts.generalAlerts : []"
      :properties="properties"
      :payments="payments"
      :rentals="rentals"
      :propList="propList"
      :loading="loading"

      @refresh-alerts="$emit('refresh-alerts')"
      @refresh-rentals="$emit('refresh-rentals')"
      @refresh-prop-list="$emit('refresh-prop-list')"
      @refresh-payments="$emit('refresh-payments')"
      @refresh-userinfo="$emit('refresh-userinfo')"
      @refresh-properties="$emit('refresh-properties')"
      @success="$emit('success', $event)"
      @error="$emit('error', $event)"
      @info="$emit('info', $event)"
      @background="$emit('background', $event)"
      @loading="$emit('loading', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'Manager',

  props: {
    env: {
      type: Object,
      required: true,
    },

    permissions: {
      type: Number,
      required: true,
      default: 0,
    },

    userInfo: {
      type: Object,
      required: true,
    },

    properties: {
      type: Array,
      required: true,
      default: [],
    },

    alerts: {
      type: Object,
      required: true,
    },

    rentals: {
      type: Array,
      required: true,
    },

    propList: {
      type: Array,
      required: true,
    },

    payments: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  watch: {

  },

  data: () => ({

  }),

  methods: {

  },

  mounted() {

  },
};
</script>
