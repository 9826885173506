import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false;
Vue.config.devServer = {};
Vue.config.devServer.disableHostCheck = true;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

const DEFAULT_TITLE = `${process.env.VUE_APP_SHORT_TITLE} | ${process.env.VUE_APP_LONG_TITLE}`;
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})
