var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-center"},[_c('h2',{staticStyle:{"font-size":"24px","margin-bottom":"15px"}},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"d-flex align-center"},[(!_vm.noAdd)?_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.addItem}},[_vm._v("Add Entry")]):_vm._e(),(!_vm.noAdd && _vm.download)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),(_vm.download)?_c('v-btn',{attrs:{"color":"accent","dark":""},on:{"click":_vm.downloadTable}},[_vm._v("Download")]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":10,"search":_vm.search,"custom-filter":_vm.filter,"loading":_vm.loading,"multi-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.amount",fn:function(ref){
var value = ref.value;
return [_vm._v(" $"+_vm._s(value)+" ")]}},{key:"item.property_url",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":_vm.getPropertyURL(value),"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.payment_date",fn:function(ref){
var value = ref.value;
return [(value && value.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.convertDateTime(value))+" ")]):_c('div')]}},{key:"item.date_of",fn:function(ref){
var value = ref.value;
return [(value && value.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.convertDate(value))+" ")]):_c('div')]}},{key:"item.start_date",fn:function(ref){
var value = ref.value;
return [(value && value.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.convertDate(value))+" ")]):_c('div')]}},{key:"item.end_date",fn:function(ref){
var value = ref.value;
return [(value && value.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.convertDate(value))+" ")]):_c('div')]}},{key:"item.check_in",fn:function(ref){
var value = ref.value;
return [(value && value.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.convertDate(value))+" ")]):_c('div')]}},{key:"item.check_out",fn:function(ref){
var value = ref.value;
return [(value && value.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.convertDate(value))+" ")]):_c('div')]}},{key:"item.due_date",fn:function(ref){
var value = ref.value;
return [(value && value.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.convertDate(value))+" ")]):_c('div')]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[(!_vm.noEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!_vm.noDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }