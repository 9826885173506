<template>
  <div>
    <router-view
      :key="$route.fullPath"
      :env="env"
      :permissions="permissions"
      :loading="loading"

      @loading="$emit('loading', $event)"
      @success="$emit('success', $event)"
      @error="$emit('error', $event)"
      @info="$emit('info', $event)"
    />
  </div>
</template>

<script>
/* IMPORTS */

export default {
  name: 'Admin',

  components: {

  },

  props: {
    env: {
      type: Object,
      required: true,
    },

    permissions: {
      type: Number,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({

  }),

  methods: {

  },

  computed: {

  },

  created () {

  },

  mounted () {
    this.$emit('background', 'white');
  },
};
</script>

<style scoped>

</style>
